import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { PlusIcon } from '@/components/common/icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  getPlatformIcon,
  getPlatformName,
  checkPhylloConfig
} from '@/biz/platform';
import PhylloSDK from '@/biz/phylloSDKInit';
import { usePlatformStore } from '@/hooks/usePlatformStore';
import { CertificationIcon } from '@/components/common/icon';
import { Tracker } from '@/utils';
import { ConnectButtonNoStyle } from '@/components/web/components/connectButtonNoStyle';
import { PlatformItems } from '@/interface/common';

const ModalSelectPlatformContent: React.FC<{}> = () => {
  const { platformList, phylloSDKConfig, loading, fetchPlatformData } =
    usePlatformStore();
  const { influencers } = usePlatformStore();
  const [connectLoading, setConnectLoading] = useState(false);

  const search = window.location.search;
  const alwaysConnect = new URLSearchParams(search)?.get('alwaysConnect');

  useEffect(() => {
    fetchPlatformData();
  }, []);

  const handleConnect = async (platform: any) => {
    const platformId = platform.id;
    const getRedirectUrl = ({
      platformType,
      workPlatformId,
      onboarding
    }: {
      platformType: string;
      workPlatformId: string;
      onboarding: boolean;
    }) => {
      return `${window.location.origin}/auth/${platformType}?work_platform_id=${workPlatformId}&onboarding=${onboarding}`;
    };

    const config = {
      clientDisplayName: 'Aha Creator',
      environment:
        process.env.REACT_APP_ENV === 'production' ? 'production' : 'staging',
      userId: phylloSDKConfig.user_id,
      token: phylloSDKConfig.sdk_token,
      redirect: true,
      redirectURL: getRedirectUrl({
        platformType: platform.platform,
        workPlatformId: platformId,
        onboarding: Boolean(!influencers?.length)
      }),
      workPlatformId: platformId,
      singleAccount: true
    };

    if (!checkPhylloConfig(config)) {
      return;
    }
    let phylloSDK = new PhylloSDK();
    await phylloSDK.openPhylloSDK(config);
  };

  const ConnectBtn = ({
    platform,
    isConnected
  }: {
    platform: any;
    isConnected: Boolean;
  }) => {
    //
    if (isConnected) {
      return <CertificationIcon className="w-6 h-6 flex-shrink-0" />;
    }

    if (platform.platform === PlatformItems.YOUTUBE) {
      return (
        <ConnectButtonNoStyle
          platform={PlatformItems.YOUTUBE}
          buttonLoading={connectLoading}
          setButtonLoading={setConnectLoading}
        >
          <Button className="mui-btn-default !p-0 !h-6 !min-w-6">
            {connectLoading ? (
              <CircularProgress className="!w-6 !h-6" disableShrink />
            ) : (
              <PlusIcon className="w-6 h-6 " />
            )}
          </Button>
        </ConnectButtonNoStyle>
      );
    } else {
      return (
        <Button
          className="mui-btn-default !p-0 !w-6 !h-6 !min-w-6"
          onClick={() => {
            if (!isConnected) {
              // google 上报
              Tracker.click('connect platform');
              Tracker.click(`connect platform ${platform.platform}`);
              handleConnect(platform);
            }
          }}
        >
          <PlusIcon className="w-6 h-6 " />
        </Button>
      );
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {platformList?.length > 0 ? (
        platformList.map((platform: any) => {
          //
          const platformFound = influencers?.find((influencer: any) => {
            return influencer.platform === platform.platform;
          });
          const isConnected = platformFound && !alwaysConnect ? true : false;

          return (
            <div
              key={platform.name}
              className="min-h-[60px] w-full px-3 py-4 rounded-lg border border-[#d9d9e0] flex justify-between items-center"
            >
              <div className="flex items-center gap-3">
                <div className="w-6 h-6">
                  {getPlatformIcon(platform.platform)}
                </div>
                {isConnected ? (
                  <div className="flex items-center text-[#60646c] text-xs gap-1">
                    <img
                      className="w-4 h-4 rounded-full"
                      src={platformFound?.profilePictureLink}
                      alt="avatar"
                    />
                    @{platformFound?.name}
                  </div>
                ) : (
                  getPlatformName(platform.platform)
                )}
              </div>
              <ConnectBtn platform={platform} isConnected={isConnected} />
            </div>
          );
        })
      ) : (
        <div className="h-[60px] w-full px-3 py-4 rounded-lg border border-[#d9d9e0] flex justify-center items-center">
          No platforms found
        </div>
      )}
    </>
  );
};

export default ModalSelectPlatformContent;
