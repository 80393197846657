import React, { useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axiosInstance from '../../apis/axiosInstance';
import { PlatformItems } from '../../interface/common';
import toast, { Toaster } from 'react-hot-toast';
import { getOrderPlatform } from '../../biz/order';
import { getLoginPageUrl, getOrderId, getLinkPageUrl } from '@/biz/tool';
import LottieClerkLoading from '@/components/web/components/LottieClerkLoading';
import { Tracker } from '@/utils';
import { getConnectErrorPageUrl } from '@/biz/tool';
const PlatformCallbackPage: React.FC = () => {
  const toastRef = useRef('');
  const { platform } = useParams();
  const location = useLocation();

  const handleCallback = async () => {
    const search = new URLSearchParams(location.search);
    const hash = new URLSearchParams(location.hash.replace(/^#/, ''));
    const error = hash.get('error');

    const loginPageUrl = getLoginPageUrl();

    // 取消登录 重定向地址为：http://localhost:5001/auth/youtube/callback#error=access_denied
    // youtube
    if (error) {
      console.log('Cancel the youtube authorization: ', error);
    } else {
      toastRef.current = toast.loading(
        `Connecting with ${getOrderPlatform({ platform })} account`
      );
    }

    let payload: any;
    const onboarding = search.get('onboarding');

    if (platform === PlatformItems.TWITTER) {
      payload = {
        oauth_token: search.get('oauth_token'),
        oauth_verifier: search.get('oauth_verifier')
      };
    } else if (platform === PlatformItems.YOUTUBE) {
      payload = {
        access_token: hash.get('access_token'),
        code: search.get('code'),
        state: search.get('state'),
        scope: search.get('scope')
      };
    } else if (platform === PlatformItems.LINKEDIN) {
      // StrictMode 下会发生多次请求, 导致 The token used in the request has been revoked by the user
      payload = {
        code: search.get('code'),
        state: search.get('state')
      };
    } else if (platform === PlatformItems.TIKTOK) {
      // 不能使用 http://localhost
      payload = {
        code: search.get('code'),
        state: search.get('state')
      };
    } else if (platform === PlatformItems.INSTAGRAM) {
      payload = {
        access_token: hash.get('access_token')
      };
    }
    payload.orderId = getOrderId();
    // 目前认为所有走到授权都是 达人 onboarding
    payload.influencerOnboarding = true;

    if (error === 'access_denied') {
      toast.error(`You have canceled connect youtube.`);
      // 2025-01-15 18:27:45 已测试
      setTimeout(() => {
        // window.location.href = '/connect';
        window.location.href = `/campaign/error?type=cancelGoogle`;
      }, 2500);
      return;
    }

    try {
      toastRef.current = toast.loading('Connecting...');
      const {
        data: { code, message, data }
      } = await axiosInstance.post(`/auth/${platform}/callback`, payload);
      toast.dismiss(toastRef.current);
      console.log('toastRef: ', toastRef);
      if (code === 0) {
        toast.success('Successfully connected');
        if (onboarding === 'true') {
          setTimeout(() => {
            window.location.href = '/contactInfo';
          }, 1000);
        } else {
          // 成功 回到首页
          window.location.href = '/';
        }
      } else {
        toast.error(message || 'Failed to auth.');
        // google 上报
        Tracker.click('connect platform failed');
        Tracker.click(`connect platform failed ${platform}`);
        setTimeout(() => {
          window.location.href = getConnectErrorPageUrl({
            platform,
            type: 'FAILED',
            onboarding,
            message: JSON.stringify(message)
          });
        }, 0);
      }
    } catch (error: any) {
      // google 上报
      Tracker.click('connect platform failed');
      Tracker.click(`connect platform failed ${platform}`);
      console.error('platform callback error', error);
      setTimeout(() => {
        let payload: any = {};
        if (error?.message?.includes('failed to get channel info')) {
          console.log('error.payload', error?.payload);
          payload = {
            platform,
            type: 'emptyChannel'
          };
          //
        } else if (
          error?.message?.includes('account has been bound by other user')
        ) {
          console.log('channelConnectedByOthers error.payload', error?.payload);
          payload = {
            emails: error?.payload?.actual?.emails,
            type: 'channelConnectedByOthers'
          };
          //
        }
        //
        window.location.href = getConnectErrorPageUrl({
          platform,
          type: 'FAILED',
          onboarding,
          message: payload?.type ? '' : JSON.stringify(error?.message),
          payload
        });
      }, 0);
    }
  };

  useEffect(() => {
    handleCallback();
  }, []);

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            fontSize: 12,
            borderRadius: 4,
            background: '#1C2024',
            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
            color: '#fff'
          }
        }}
      />
      <Toaster
        toastOptions={{
          style: {
            fontSize: 12,
            borderRadius: 4,
            background: '#1C2024',
            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
            color: '#fff'
          }
        }}
      />
      <div className="flex flex-col justify-center items-center h-screen text-gray-500">
        <div className="text-2xl mt-[-16%] flex flex-col justify-center items-center">
          <LottieClerkLoading />
          <p className="mt-4">Redirecting to Aha Creator...</p>
        </div>
      </div>
    </>
  );
};

export default PlatformCallbackPage;
