import React, { useState, useEffect } from 'react';
import { TimeLineDot } from '@/components/common/icon';
import { isMobile } from '@/biz/tool';
// import { showContentButton } from "@/biz/order";
// import ModalDelayTwoDays from "@/components/modal/ModalDelayTwoDays";
// import DrawFeedback from "@/components/modal/DrawFeedback/index";
// import ModalApprove from "@/components/modal/ModalApprove";
import { formatDateTime_YMD_HMS } from '@/biz/tool';
// import { Image } from 'antd';
import FeedbackMediaList from './FeedbackMediaList';

const getHistoryStatus = (status: string) => {
  if (status === 'Feedback_provided') {
    return 'Feedback provided';
  }
  if (status === 'Deal_canceled') {
    return 'Deal canceled';
  }
  if (status === 'Under_review') {
    return 'Under review';
  }
  if (status === 'Approved') {
    return 'Approved';
  }
  if (status === 'Submitted') {
    return 'Submitted';
  }
  return status;
};

const getHistoryTitle = (stage: string) => {
  let title = '';
  if (stage === 'Script') {
    title = 'Script';
  }
  if (stage === 'Draft_1') {
    title = 'Video draft 1';
  }
  if (stage === 'Draft_2') {
    title = 'Video draft 2';
  }
  if (stage === 'Draft_3') {
    title = 'Video draft 3';
  }
  if (stage === 'Final') {
    title = 'Final Link';
  }
  return title;
};

const SubmitHistory: React.FC<{
  orderDetail: any;
}> = React.memo(({ orderDetail }) => {
  const [uploadedRecords, setUploadedRecords] = useState<any[]>([]);

  useEffect(() => {
    if (orderDetail?.contents) {
      const temp = [...orderDetail?.contents]?.reverse() || [];
      if (
        orderDetail?.skipScript &&
        !orderDetail?.contents?.find(
          (item: any) => item?.stage === 'Script' && item?.status === 'Skipped'
        )
      ) {
        temp.push({
          stage: 'Script',
          status: 'Skipped',
          systemText:
            'The creator has chosen to bypass the scriptwriting stage and proceed directly to video production.',
          submitAt: ''
        });
      }
      setUploadedRecords(temp);
    }
  }, [orderDetail?.contents]);

  const WebItem = ({ item, index }: { item: any; index: number }) => {
    const feedback = item.feedback;
    const suggestion = item.suggestion;

    return (
      <div
        className={`w-full pt-0 bg-white rounded-lg mb-4
          ${isMobile() ? 'py-4' : 'p-4'}
        `}
      >
        <div className="flex flex-col text-xs">
          <div className="flex justify-between items-center mb-3">
            <div
              className={`flex items-center gap-2
                ${isMobile() ? 'w-full flex items-start justify-between' : ''}`}
            >
              <div className="text-base font-medium">
                {getHistoryTitle(item?.stage)}
              </div>
              <div
                className={`px-[10px] py-1 rounded-2xl font-semibold
                ${
                  item?.status === 'Feedback_provided' ||
                  item?.status === 'Under_review'
                    ? 'bg-white text-[##09090b] border border-[#d9d9e0]'
                    : ''
                }
                ${
                  item?.status === 'Deal_canceled'
                    ? 'bg-[#FFF1F0] text-[#CF1322]'
                    : ''
                }
                ${
                  item?.status === 'Approved' || item?.status === 'Submitted'
                    ? 'bg-[#4caf50] text-white'
                    : ''
                }
                ${item?.status === 'Skipped' ? 'bg-[#000000] text-white' : ''}`}
              >
                {getHistoryStatus(item?.status)}
              </div>
            </div>
            {!isMobile() && (
              <div
                className="text-[#666]"
                style={{
                  display: item?.status === 'Skipped' ? 'none' : 'block'
                }}
              >
                Submitted on: {formatDateTime_YMD_HMS(item?.submitAt)}
              </div>
            )}
          </div>

          {item?.url && (
            <div className="mb-3">
              <span className="text-[#666]">
                {item?.stage === 'Script' ? 'Script' : 'Video'} link:{' '}
              </span>
              <a
                target="_blank"
                className="text-[#0c67e6] whitespace-normal break-words hover:underline break-all "
                href={item?.url}
                rel="noreferrer"
              >
                {item?.url}
              </a>
            </div>
          )}

          {item?.note && (
            <div className="text-[#666] whitespace-normal break-words mb-3">
              Note: {item?.note}
            </div>
          )}
          {item?.systemText && (
            <div className="text-[#666] whitespace-normal break-words mb-3">
              {item?.systemText}
            </div>
          )}

          {isMobile() && (
            <div
              className="text-[#666] mb-4"
              style={{ display: item?.status === 'Skipped' ? 'none' : 'block' }}
            >
              Submitted on: {formatDateTime_YMD_HMS(item?.createdAt)}
            </div>
          )}

          {item?.status === 'Feedback_provided' &&
            (!!feedback?.length || !!suggestion?.length) && (
              <div className="w-[751px] px-6 py-4 bg-[#fff6ee] rounded-lg flex-col justify-start items-start gap-8 inline-flex">
                {!!feedback?.length && (
                  <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                    <div className="self-stretch text-[#1c2024] text-base font-semibold font-['Geist'] leading-normal">
                      Requirements
                    </div>
                    {feedback?.map(
                      (
                        item: {
                          text: string;
                          imageList?: string[];
                          videoList?: string[];
                        },
                        index: number
                      ) => (
                        <div className="self-stretch flex-col justify-start items-start flex">
                          <div className="self-stretch flex-col justify-start items-start flex">
                            <div className="self-stretch text-[#1c2024] text-sm font-normal font-['Inter'] leading-snug">
                              {index + 1}. {item?.text}
                            </div>
                          </div>
                          <FeedbackMediaList
                            imageList={item?.imageList as string[]}
                            videoList={item?.videoList as string[]}
                            isShowDelete={false}
                          />
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            )}
          {item?.status === 'Feedback_provided' &&
            (!!suggestion?.length || !!suggestion?.length) && (
              <div className="w-[751px] px-6 py-4 bg-[#fff6ee] rounded-lg flex-col justify-start items-start gap-8 inline-flex">
                {!!suggestion?.length && (
                  <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                    <div className="self-stretch text-[#1c2024] text-base font-semibold font-['Geist'] leading-normal">
                      Additional suggestions
                    </div>
                    {suggestion?.map(
                      (
                        item: {
                          text: string;
                          imageList?: string[];
                          videoList?: string[];
                        },
                        index: number
                      ) => (
                        <div className="self-stretch flex-col justify-start items-start flex">
                          <div className="self-stretch flex-col justify-start items-start flex">
                            <div className="self-stretch text-[#1c2024] text-sm font-normal font-['Inter'] leading-snug">
                              {index + 1}. {item?.text}
                            </div>
                          </div>
                          <FeedbackMediaList
                            imageList={item?.imageList as string[]}
                            videoList={item?.videoList as string[]}
                            isShowDelete={false}
                          />
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            )}
          {/* {index === 0 && (
            <div className="flex gap-3 h-8">
              {showContentButton(orderDetail?.status).map((buttonName) => {
                if (buttonName === "Postpone the timeline") {
                  return (
                    <ModalDelayTwoDays
                      buttonSize="middle"
                      content={"content"}
                    />
                  );
                } else if (buttonName === "Provide feedback") {
                  return <DrawFeedback />;
                } else if (buttonName === "Approve") {
                  return <ModalApprove />;
                } else {
                  return null;
                }
              })}
            </div>
          )} */}
        </div>
      </div>
    );
  };

  const TimeLineItem = ({ list }: { list: any }) => {
    return (
      <div
        className={`flex flex-col 
        ${isMobile() ? '' : 'ml-2 mr-8'}
        `}
      >
        {list.map((el: any, index: number) => {
          const isLastOne = index + 1 === list.length;
          return (
            <div
              className={`justify-start items-center inline-flex relative w-full`}
              key={el.submitAt}
            >
              {isLastOne ? (
                ''
              ) : (
                <div
                  className={`w-[42px] h-full flex justify-center absolute top-3
                ${isMobile() ? 'ml-[-16px]' : ''}`}
                >
                  <div className="w-[2px] h-[calc(100%-10px-9px)] bg-[#d9d9e0] absolute top-[10px]"></div>
                </div>
              )}
              <div
                className={`w-[42px] flex justify-center self-start mt-[6px] z-0 bg-white flex-shrink-0 
                  ${isMobile() ? 'ml-[-16px]' : ''}`}
              >
                <TimeLineDot className="w-3 h-3" />
              </div>
              <div
                className={`flex-col justify-start items-start gap-1 inline-flex
                  ${
                    isMobile()
                      ? 'w-full max-w-[calc(100%-28px)]'
                      : 'w-[calc(100%-36px)]'
                  }
                `}
              >
                <WebItem item={el} index={index} />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  // if (!uploadedRecords?.length) return null;

  return (
    <div
      className={`w-full pb-12
      ${isMobile() ? '' : 'mt-8'}
    `}
    >
      <TimeLineItem list={uploadedRecords} />
    </div>
  );
});

export default SubmitHistory;
